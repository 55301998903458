<template>
    <article class="msg d-flex flex-nowrap">
        <div class="bubble flex-grow-1 shadow-sm rounded-xl"
             v-bind:class="{ 'alt': isSelf }">
            <div class="position-relative content pt-1 pb-2">
                <div class="d-flex align-items-center px-2">
                    <div class="name mr-2">
                        <template v-if="isSelf">
                            Self
                        </template>
                        <template v-else-if="type === 'whatsapp'">
                            <span class="mr-1">
                                {{ message.author.phone }}
                            </span>
                            <span>
                                <small v-if="message.author.pushname">
                                    {{ message.author.pushname }}
                                </small>
                            </span>
                        </template>
                        <template v-else-if="type === 'telegram'">
                            <span class="mr-1">
                                {{ message.author.name }}
                            </span>
                            <span>
                                <small v-if="message.author.formattedName">
                                    {{ message.author.formattedName }}
                                </small>
                            </span>
                        </template>
                    </div>
                    <div class="timestamp">
                        {{ message.timestamp | dateFnsFormatter("h:mm a") }}
                    </div>
                </div>

                <div class="quoted rounded-lg mx-1 mb-1 px-2 py-1"
                     v-if="message.quoted">
                    <div class="name">
                        {{ message.quoted.author.phone }}
                    </div>
                    <div class="message d-flex">
                        <span v-if="message.quoted.type === 'chat'"
                              v-html="message.quoted.body">
                        </span>
                        <span v-else-if="message.quoted.type === 'sticker'">
                            <font-awesome-icon class="mr-1"
                                               icon="comment-alt-smile">
                            </font-awesome-icon>
                            Sticker
                        </span>
                        <span v-else-if="message.quoted.type === 'image'">
                            <font-awesome-icon class="mr-1"
                                               icon="images">
                            </font-awesome-icon>
                            Image
                        </span>
                        <span v-else>
                            Others
                        </span>
                    </div>
                </div>

                <div class="message d-flex px-2">
                    <span v-if="message.type === 'chat'"
                          v-html="parsedBody">
                    </span>
                    <span v-else-if="message.type === 'sticker'">
                        <font-awesome-icon class="mr-1"
                                           icon="comment-alt-smile">
                        </font-awesome-icon>
                        Sticker
                    </span>
                    <span v-else-if="message.type === 'image'">
                        <font-awesome-icon class="mr-1"
                                           icon="images">
                        </font-awesome-icon>
                        Image
                    </span>
                    <span v-else-if="message.type === 'video'">
                        <font-awesome-icon class="mr-1"
                                           icon="video">
                        </font-awesome-icon>
                        Video
                    </span>
                    <span v-else-if="message.type === 'ptt'">
                         <font-awesome-icon class="mr-1"
                                            icon="microphone">
                        </font-awesome-icon>
                        Voice Message
                    </span>
                    <span v-else
                          v-text="Others">
                    </span>
                </div>
            </div>
            <div class="bubble-arrow"></div>
        </div>
        <div class="options d-flex align-items-center pl-2"
             v-if="!isSelf">
            <b-dropdown no-caret
                        variant="outline-dark"
                        boundary="scrollParent"
                        menu-class="border-0 py-1 shadow-sm rounded-lg"
                        toggle-class="btn-dropdown rounded-circle border-0 shadow-sm">
                <template v-slot:button-content>
                    <font-awesome-icon icon="bars"></font-awesome-icon>
                </template>
                <b-dropdown-item>
                    🗑️ Remove
                </b-dropdown-item>
            </b-dropdown>
        </div>
    </article>
</template>

<script>
import linkify from "linkify-it";
import tlds from "tlds";

const linkifyInstance = linkify()
    .tlds(tlds)
    .tlds("onion", true)
    .set({
        "fuzzyIP": true
    });

import { BDropdown, BDropdownItem, } from "bootstrap-vue";

export default {
    name: "message",
    components: {
        BDropdown, BDropdownItem,
    },
    props: {
        "message": {
            type: Object,
            required: true,
        },
        "type": {
            type: String,
            default: "whatsapp",
        },
    },
    data () {
        return {

        };
    },
    computed: {
        isSelf () {
            return this.message?.isSelf ?? false;
        },
        parsedBody () {
            if (
                this.message.body &&
                ["chat"].includes(this.message.type)
            ) {
                let clone = this.message.body;
                const matches = linkifyInstance.match(clone);
                if (
                    Array.isArray(matches) &&
                    matches.length > 0
                ) {
                    let addLength = 0;
                    matches.forEach(
                        match => {
                            clone =
                                `${ clone.slice(0, match.index + addLength) }<a target='_blank' href='${ match.raw }'>${ match.raw }</a>${ clone.slice(match.lastIndex + addLength) }`;
                            addLength += (`<a target='_blank' href='${ match.raw }'>${ match.raw }</a>`.length - match.raw.length);
                        }
                    );
                }
                return clone;
            }
            return this.message.body;
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.msg {
    width: 100%;
    height: auto;
    display: block;
    overflow: hidden;

    .bubble {
        max-width: 80%;
        width: auto;
        height: auto;
        display: block;
        background-color: rgba(245, 245, 245, 1);
        position: relative;
        margin: 10px 0 10px 10px;

        &.alt {
            margin: 10px 10px 10px auto;
            background: rgba(220, 248, 198, 1);
        }

        &.follow {
            margin: 2px 0 3px 25px;
        }

        &.altfollow {
            margin: 2px 25px 3px 0;
            background: rgba(220, 248, 198, 1);
        }

        .content {
            width: 100%;
            z-index: 1;

            .name {
                font-weight: 600;
                font-size: 14px;
                color: rgba(52, 152, 219, 1);

                margin: 0 0 4px 0;

                flex: 1;

                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                span:last-child {
                    font-weight: normal;
                    color: rgba(179, 179, 179, 1);
                }

                &.alt {
                    color: rgba(46, 204, 81, 1);
                }
            }

            .quoted {
                background-color: rgba(235, 235, 235, 1);
                border-radius: 2.5px;
                border: 1px solid rgba(200, 200, 200, 1);
                border-left: 4px solid rgba(255, 152, 0, 1);
            }

            .message {
                font-size: 14px;
                font-weight:500;
                text-align: left;

                color: rgba(43, 43, 43, 1);

                white-space: pre-line;
                word-break: break-word;

                & > span {
                    width: 100%;
                }
            }

            .timestamp {
                font-size: 11px;
                color: rgba(153, 153, 153, 1);
                text-transform: uppercase;
            }
        }

        .bubble-arrow {
            position: absolute;
            left: -11px;
            top: 0;

            &:after {
                content: "";
                position: absolute;
                border-top: 30px solid rgba(245, 245, 245, 1);
                border-left: 30px solid transparent;
                border-radius: 7.5px 0 0 0;
                width: 0;
                height: 0;
            }
        }

        &.alt {
            .bubble-arrow {
                left: auto;
                right: 15px;

                &:after {
                    border-top: 30px solid rgba(220, 248, 198, 1);
                    transform: scaleX(-1);
                }
            }
        }
    }
}
</style>

<style lang="scss">
.btn-dropdown {
    width: 38px;
    height: 38px;
    background-color: rgba(0, 0, 0, 0.1);
}
</style>
